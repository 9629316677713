angular.module('app').directive('gradFill', ['$location', function($location) {
  'use strict';


  return {
    restrict: 'A',
    link: function($scope, $element, $attrs) {
      $attrs.$set('fill', $scope.absUrl($attrs['gradFill']));
    },
    controller : ['$scope', '$location', function($scope, $location){
      $scope.absUrl = function (gradIri) {
        return 'url(' + $location.absUrl() + '#' + gradIri.match(/.*\(#(.*)\)/)[1] + ')';
      };
    }]
  };
}]);